/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Code+Pro:ital@1&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* font-family: 'Raleway', sans-serif; */
    box-sizing: border-box;
  }
  *,*:before,*:after {
    box-sizing: inherit;
    }
    body{
    margin: 0;
    background-color: #f5f5f5;
    }

}

:root{
  scrollbar-color: rgba(171, 172, 200, 0.794) #ffffff !important;
  scrollbar-width: thin !important;
}

/* 

.ReactCollapse--collapse {
  transition: height 500ms;
} */

/* 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #253341 inset;
} */